import {convertStyles} from "../../../services/utils"
import {FC} from "react";

interface IProps {
    item: any
}

const Editor: FC<IProps> = ({item}) => {


    const setStyles = convertStyles(item.settings) || {};

    return (

        <div
            //@ts-ignore
            data-aos={setStyles?.animationAos['data-aos']}
            //@ts-ignore
            style={{...setStyles.parentStyles}}
            // style={{...setStyles.parentStyles}}
        >

            <div
                /*@ts-ignore*/
                style={{...setStyles.styles}}
                dangerouslySetInnerHTML={{
                    __html: item.textEditorValue,
                }}
            />

        </div>
    );
};

export default Editor;
